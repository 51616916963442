<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <div class="search_box_title">{{ $t("searchModule.Query_Table") }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item label="设备编码">
                <el-autocomplete
                  class="inline-input"
                  v-model="modelvalue1"
                  :fetch-suggestions="querySearchAsync1"
                  placeholder="设备编码"
                  value-key="equipmentCode"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Equipment_name')">
                <el-autocomplete
                  class="inline-input"
                  v-model="modelvalue2"
                  :fetch-suggestions="querySearchAsync2"
                  placeholder="设备名称"
                  value-key="equipmentName"
                  :trigger-on-focus="false"
                  @select="handleSelect2"
                ></el-autocomplete>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  page = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t("button.search") }}</el-button
              >
            </div>
          </div>
          <div class="col_box_boder" v-if="$route.meta.authority.button.add"></div>
          <div class="col_box h44" v-if="$route.meta.authority.button.add">
            <div class="col_left">
              <el-button
                type="primary"
                v-if="$route.meta.authority.button.add"
                icon="el-icon-plus"
                @click="addInspectione"
                >添加
              </el-button>
            </div>
            <div class="col_right mbd4"></div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          header-cell-class-name="header-call-style"
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column
            type="index"
            :label="$t('list.index')"
            width="70"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <!-- <el-table-column type="selection" width="55"></el-table-column> -->
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="操作" align="center" width="80">
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" style="padding: 0" size="small"
                  >操作<i class="el-icon-arrow-down" style="margin-left: 4px"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="a">查看</el-dropdown-item>
                  <el-dropdown-item command="b">编辑</el-dropdown-item>
                  <el-dropdown-item command="c">{{
                    scope.row.equipmentState == 1 ? "下线" : "上线"
                  }}</el-dropdown-item>
                  <el-dropdown-item command="d">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <inspectionAdd
      ref="insp"
      :centerDialogVisible="centerDialogVisible"
      @closeInspectione="closeInspectione"
    ></inspectionAdd>
  </div>
</template>
<script>
import inspectionAdd from "./inspectionAdd";
export default {
  name: "brandManage",
  data() {
    return {
      modelvalue1: "",
      modelvalue2: "",
      tableData: [],
      centerDialogVisible: false,

      index: 0,
      total: 0,
      page: 1,
      pageSize: 15,
      loading: false,
      tableCols: [
        {
          prop: "equipmentCode",
          label: "设备编码",
          width: "",
        },
        {
          prop: "equipmentName",
          label: this.$t("list.Equipment_name"),
          width: "",
        },
        {
          prop: "brandName",
          label: "设备品牌",
          width: "",
        },
        {
          prop: "operationName",
          label: this.$t("list.Merchant_affiliation"),
          width: "",
        },
        {
          prop: "parkNames",
          label: "所辖道路",
          width: "",
        },
        {
          prop: "equipmentState",
          label: "设备状态",
          width: "",
          formatter: (row, column) => {
            if (row.equipmentState == 1) {
              return "上线";
            } else {
              return "下线";
            }
          },
        },
      ],
      formInline: {
        equipmentCode: "",
        equipmentName: "",
      },
    };
  },
  methods: {
    addInspectione() {
      this.centerDialogVisible = true;
    },
    closeInspectione() {
      this.centerDialogVisible = false;
      this.searchData();
    },
    querySearchAsync1(queryString, cb) {
      this.$emit("valueChange", "");
      queryString = queryString.replace(/\s/g, "");
      let url = "/acb/2.0/equipment/inspection/like/code/" + this.modelvalue1;
      this.$axios
        .get(url, {
          data: {},
        })
        .then((res) => {
          if (res.state == 0) {
            cb(res.value);
          } else {
            this.$alert(res.desc, this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
          }
        });
    },
    handleSelect(item) {
      console.log("-->", item);
      this.formInline.equipmentCode = item.equipmentCode;
    },
    querySearchAsync2(queryString, cb) {
      this.$emit("valueChange", "");
      queryString = queryString.replace(/\s/g, "");
      let url = "/acb/2.0/equipment/inspection/like/name/" + this.modelvalue2;
      this.$axios
        .get(url, {
          data: {},
        })
        .then((res) => {
          if (res.state == 0) {
            cb(res.value);
          } else {
            this.$alert(res.desc, this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
          }
        });
    },
    handleSelect2(item) {
      this.formInline.equipmentName = item.equipmentName;
    },
    // 查看
    seeData() {},
    // 编辑
    editData() {},
    // 上下线
    upData(data) {
      console.log("-->", data);
      let tagState = data.equipmentState == 1 ? 2 : 1;
      this.$axios
        .post("/acb/2.0/equipment/updateEquipmentState/" + data.equipmentId + "/" + tagState, {
          data: {},
        })
        .then((res) => {
          console.log("res", res);
          this.searchData();
        });
    },
    // 删除
    dateleData(data) {
      this.$axios
        .post("/acb/2.0/equipment/inspection/delete/" + data.equipmentId, {
          data: {},
        })
        .then((res) => {
          console.log("res", res);
          this.searchData();
        });
    },
    handleCommand(cmd, data) {
      if (cmd == "a") {
        this.$refs.insp.getDetails(data.equipmentId, true);
        this.centerDialogVisible = true;
      }
      if (cmd == "b") {
        // console.log("data-->", data)
        this.$refs.insp.getDetails(data.equipmentId);
        this.centerDialogVisible = true;
      }
      if (cmd == "c") {
        this.$confirm("此操作将改变设备状态，是否继续？", this.$t("pop_up.Tips"), {
          confirmButtonText: this.$t("pop_up.Determine"),
          cancelButtonText: "取消",
        })
          .then(() => {
            this.upData(data);
          })
          .catch(() => {});
      }
      if (cmd == "d") {
        this.$confirm("此操作将删除设备，是否继续？", this.$t("pop_up.Tips"), {
          confirmButtonText: this.$t("pop_up.Determine"),
          cancelButtonText: "取消",
        })
          .then(() => {
            this.dateleData(data);
          })
          .catch(() => {});
      }
    },
    // 分页处理
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    // 搜索
    searchData() {
      this.tableData = [];
      this.loading = true;
      this.$axios
        .get("/acb/2.0/equipment/inspection/list", {
          data: {
            page: this.page,
            pageSize: this.pageSize,
            ...this.formInline,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.tableData = res.value.list;
            this.total = res.value.total * 1;
          } else {
            this.$alert(res.desc, this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
          }
        });
    },
  },
  beforeDestroy() {
    sessionStorage.searchData = JSON.stringify(this.formInline);
    sessionStorage.searchFlag = 1;
  },
  activated() {
    this.searchData();
  },
  created() {
    this.searchData();
  },
  computed: {},
  watch: {
    modelvalue1(val, oldVal) {
      if (val == "") {
        this.formInline.equipmentCode = "";
      }
    },
    modelvalue2(val, oldVal) {
      if (val == "") {
        this.formInline.equipmentName = "";
      }
    },
  },
  components: {
    inspectionAdd,
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.content {
  overflow: hidden;



  .pagerWrapper {
    text-align: right;
    margin-top: 18px;
    font-size: 12px;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.el-dropdown-link {
  cursor: pointer;
}
</style>
