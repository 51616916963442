<template>
  <div>
    <el-dialog
      title="添加巡检车"
      :visible.sync="centerDialogVisible"
      width="800px"
      center
      :before-close="close"
    >
      <el-form ref="form" :model="formAdd" label-width="100px" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item :label="$t('searchModule.Equipment_name')" prop="equipmentName">
              <el-input
                :disabled="seeTag"
                v-model="formAdd.equipmentName"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="10">
            <el-form-item label="设备编码" prop="equipmentCode">
              <el-input
                :disabled="seeTag"
                v-model="formAdd.equipmentCode"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item
              :label="$t('searchModule.Belonging_operator')"
              label-width="100px"
              prop="operationId"
            >
              <el-select
                v-model.trim="formAdd.operationId"
                @change="querySelect"
                filterable
                size="15"
                :disabled="seeTag"
              >
                <el-option label="全部" value=""></el-option>
                <el-option
                  :label="value.operationName"
                  :value="value.operationId"
                  :key="value.operationId"
                  v-for="value in roleList"
                ></el-option>
              </el-select> </el-form-item
          ></el-col>
          <el-col :span="10">
            <el-form-item label="设备品牌" prop="brandId">
              <el-select v-model.trim="formAdd.brandId" filterable size="15" :disabled="seeTag">
                <el-option label="全部" value=""></el-option>
                <el-option
                  :label="value.brandName"
                  :value="value.brandId"
                  :key="value.brandId"
                  v-for="value in roleList1"
                ></el-option>
              </el-select> </el-form-item
          ></el-col>
        </el-row>
        <el-form-item
          label="所辖道路:"
          style="width: 700px"
          prop="parkIds"
          v-if="!seeTag"
          label-width="95px"
        >
          <el-transfer
            target-order="push"
            :titles="['未选择', '已选择']"
            filterable
            :props="{
              key: 'parkId',
              label: 'parkName',
            }"
            filter-placeholder="请输入车场名称"
            v-model="selected"
            :data="parkList"
          >
          </el-transfer>
        </el-form-item>
        <el-form-item label="所辖道路:" v-else label-width="95px">
          <div v-for="item in parkNameList" :key="item">{{ item }}</div>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="submitData()" v-if="!seeTag">{{
            $t("button.preservation")
          }}</el-button>
          <el-button type="primary" v-if="$route.meta.authority.button.add" @click="close"
            >取消
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      parkNameList: [],
      seeTag: false,
      equipmentIdTag: "",
      urlTag: 1,
      parkList: [],
      selected: [],
      formAdd: {
        equipmentCode: "",
        equipmentName: "",
        brandId: "",
        operationId: "",
      },
      roleList: [],
      roleList1: [],
      showParkingList: [],
      operationId111: "",
      rules: {
        operationId: [
          {
            required: true,
            message: "请选择商户",
            trigger: "change",
          },
        ],
        brandId: [
          {
            required: true,
            message: "请选择设备品牌",
            trigger: "change",
          },
        ],
        equipmentName: [
          {
            required: true,
            message: "请输入设备名称",
            trigger: "blur",
          },
        ],
        equipmentCode: [
          {
            required: true,
            message: "请输入设备编码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: {
    centerDialogVisible: {
      default: false,
    },
  },
  created() {
    this.getChildrenArea();
    // this.getChildrenArea1();
    // this.queryParkList();
  },
  methods: {
    // 运营商和品牌及车场列表关联
    querySelect() {
      this.getChildrenArea1();
      this.queryParkList();
    },
    // 查询车场列表
    queryParkList(val) {
      this.$axios
        .get("/acb/2.0/systems/loginUser/parkNameList", {
          data: {
            dataSource: 1,
            slaveRelations: "0,2",
            operationId: this.formAdd.operationId,
          },
        })
        .then((res) => {
          if (res.state != 0) {
            this.$alert(res.desc);
            return;
          }
          this.parkList = res.value;
        });
    },
    close() {
      this.$emit("closeInspectione");
      this.formAdd.equipmentCode = "";
      this.seeTag = false;
      this.parkList = [];
      this.formAdd.equipmentName = "";
      this.formAdd.brandId = "";
      this.formAdd.operationId = "";
      this.selected = [];
      this.$refs.form.resetFields();
    },
    getChildrenArea() {
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/operation/list",
        data: {
          page: 0,
          pageSize: 0,
          companyName: "",
          bearSide: "",
        },
        success: (res) => {
          this.loading = false;
          if (res.state == 0) {
            this.roleList = res.value;
          } else {
            this.$alert(res.desc, this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
          }
        },
      };
      this.$request(opt);
    },
    getChildrenArea1() {
      this.$axios
        .get("/acb/2.0/brand/list", {
          data: {
            page: 0,
            pageSize: 100,
            operationId: this.formAdd.operationId,
            brandId: "",
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.roleList1 = res.value.list;
          } else {
            this.$alert(res.desc, this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
          }
        });
    },
    submitData() {
      // if (this.selected.length == 0) {
      //   this.$alert("请选择车场", this.$t('pop_up.Tips'), {
      //     confirmButtonText: this.$t('pop_up.Determine'),
      //   });
      //   return;
      // }
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.saveData();
        } else {
          return false;
        }
      });
    },
    saveData() {
      let parkIds = "";
      if (this.selected && this.selected.length > 0) {
        parkIds = this.selected.join(",");
      }
      if (this.urlTag == 1) {
        this.$axios
          .post("/acb/2.0/equipment/inspection/add", {
            data: {
              ...this.formAdd,
              parkIds,
            },
          })
          .then((res) => {
            this.close();
          });
      } else {
        this.$axios
          .post("/acb/2.0/equipment/inspection/update", {
            data: {
              ...this.formAdd,
              parkIds,
              equipmentId: this.equipmentIdTag,
            },
          })
          .then((res) => {
            this.close();
          });
      }
    },
    getDetails(item, seeTag) {
      //   this.queryParkList();
      this.urlTag = 2;
      let that = this;
      this.equipmentIdTag = item;
      this.seeTag = seeTag;
      this.$axios
        .get("/acb/2.0/equipment/inspection/detail/" + item, {
          data: {},
        })
        .then((res) => {
          let dataDetails = res.value;
          that.formAdd.equipmentName = dataDetails.equipmentName;
          that.formAdd.equipmentCode = dataDetails.equipmentCode;
          that.formAdd.operationId = dataDetails.operationId;
          that.formAdd.brandId = dataDetails.brandId;
          let add = [];
          let parkNames = [];
          if (dataDetails.parks && dataDetails.parks.length > 0) {
            dataDetails.parks.forEach((element) => {
              add.push(element.parkId);
              parkNames.push(element.parkName);
            });
          }
          this.parkNameList = parkNames;
          this.selected = add;
          this.querySelect();
        });
    },
  },
};
</script>
